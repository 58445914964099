<template>
  <div
    class="forensics"
    v-if="initShow"
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="platecont">
      <div class="title-text">
        <div class="inbox">泊位号：{{ berthNo }}</div>
      </div>
      <div class="plateinput-title">车牌号码</div>
      <div class="plate-box">
        <div class="input-plate-wrap">
          <div class="plate-for" v-for="(item, index) in keyBoard.kbLenght" :key="index" @click="getmenuItem(index)">
            <div
              class="plate-item"
              v-if="index != keyBoard.kbLenght - 1"
              :class="{ active: index === keyBoard.indexNum }"
              @click="carPlateInput(index)"
            >
              <i v-if="keyBoard.value[index] == ''" style="color: #a3a3a3; font-size: 18px"> - </i>
              {{ keyBoard.value[index] || "" }}
            </div>
            <div
              class="plate-item-new"
              v-if="index == keyBoard.kbLenght - 1"
              :class="{ active: index === keyBoard.indexNum }"
              @click="carPlateInput(index)"
            >
              <i
                v-if="keyBoard.value[keyBoard.kbLenght - 1] == '' && keyBoard.value.length == 8"
                style="color: #00deb9; font-size: 12px; writing-mode: vertical-rl"
              >
                新能源
              </i>
              {{ keyBoard.value[index] }}
            </div>
          </div>
        </div>
      </div>
      <div class="plateColor">
        <div
          v-for="(item, index) in plateColorList"
          :key="index"
          :class="{
            'active-click': nowIndex == index,
            'plateColor-item': true,
          }"
          @click="handleplateColor(index)"
        >
          <div v-if="nowIndex == index" style="color: white; font-size: 16px; margin-right: 4px">
            <van-icon name="success" />
          </div>
          {{ item }}
        </div>
      </div>
      <div class="car-type">
        <div class="car-type-title">车辆类型：</div>
        <div class="car-type-checkbox">
          <van-radio-group v-model="plateType" direction="horizontal">
            <van-radio :name="1">小车</van-radio>
            <van-radio :name="3">大车</van-radio>
          </van-radio-group>
        </div>
      </div>
      <div class="record-plate" v-show="recordPlateList.length">
        <div class="record-plate-title">已认证车牌：</div>
        <div class="plate-list">
          <div
            class="license-plate"
            v-for="plate in recordPlateList"
            :key="plate.id"
            @click="plateClickHandle(plate)"
            :style="{ backgroundColor: plateColorListcheck[plate.color] }"
          >
            <div class="number" :style="{ color: plateTextColor[plate.color] }">
              {{ plate.plateNo }}
            </div>
          </div>
        </div>
      </div>
      <div class="step">
        <div class="steptext">停车缴费步骤</div>
        <div class="firststep">
          <div class="firstbox">第一步：入场时扫码取证</div>
        </div>
        <div class="stepicon"><van-icon name="down" /></div>
        <div class="firststep">
          <div class="firstbox">第二步：出场时扫码缴费</div>
        </div>
      </div>
      <div class="submitbox">
        <van-button round type="info" class="submit" @click="onSubmit" v-preventReClick="3000"> 确认取证 </van-button>
      </div>
      <div>
        <PlateInput
          :kbShow="keyBoard.isShow"
          :kbIndex="keyBoard.indexNum"
          :kbType="keyBoard.length"
          @childValue="keyboardValue"
          @childClose="keyboardClose"
          @childIndex="keyboardIndex"
          @childDel="keyboardDel"
        />
      </div>
    </div>
    <!-- 取证成功弹框 -->
    <van-popup
      v-model="showalert"
      @click-close-icon="closealert"
      :close-on-click-overlay="false"
      round
      closeable
      :style="{ height: '20%', width: '80%', padding: '40px 10px' }"
    >
      <div class="alert-titel">
        <div style="margin-right: 5px; font-size: 28px">
          <van-icon name="checked" />
        </div>
        <div>取证成功</div>
      </div>
      <div class="alert-text" v-if="appletsList.isFollow">点击下方按钮查看{{ appletsList.appName }}公众号详情</div>
      <div class="alert-text" v-else>为方便缴费停车，请关注{{ appletsList.appName }}公众号获取推送信息</div>
      <div class="alert-btn">
        <van-button style="width: 60%" round type="info" @click="checkaccount">
          {{ appletsList.isFollow ? "查看公众号" : "去关注" }}
        </van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import PlateInput from "./components/plateInput";

export default {
  name: "Forensics",
  components: {
    PlateInput,
  },
  data() {
    return {
      showalert: false,
      // 泊位id
      berthNo: null,
      initShow: true,
      // 车辆类型
      plateType: 1,
      // 车牌选择
      plateColor: 1,
      keyBoard: {
        //键盘
        isShow: false,
        kbLenght: 8, // 车牌输入框长度
        indexNum: " ", // 点击车牌设置焦点的下标
        value: ["", "", "", "", "", "", "", ""], // 键盘值
        berthId: null, // 泊位id
        roadId: null, // 路段id
      },
      // 已经认证的车牌列表
      recordPlateList: [],
      // 车牌颜色列表
      plateColorListcheck: {
        1: "#4395ff",
        2: "#eeda47",
        3: "#000000",
        4: "#FFFFFF",
        5: "#11f28f",
      },
      // 车牌字体颜色
      plateTextColor: {
        1: "white",
        2: "black",
        3: "white",
        4: "black",
        5: "black",
      },
      // 公众号信息
      appletsList: {},
      loading: false,
      plateColorList: ["蓝牌车", "黄牌车", "黑牌车", "白牌车", "绿牌车"],
      nowIndex: 0,
      domain: process.env.VUE_APP_TESTLOCAL_DOMAIN,
    };
  },
  created() {
    let obj = this.getRequest(window.location.href);
    alert(JSON.stringify(obj));
    // this.getData();
  },
  methods: {
    // 获取URL中的字符串
    getRequest(str) {
      let url = str ? str : decodeURIComponent(location.search); //获取url中"?"符后的字串
      let theRequest = new Object();
      if (url.indexOf("?") != -1) {
        let index = url.indexOf("?");
        url = url.substr(index + 1);
      } else {
        alert("地址错误，请重新扫码");
      }
      if (url) {
        var strs = url.split("&");
        for (var i = 0; i < strs.length; i++) {
          var srtArry = strs[i].split("=");
          var y = srtArry.shift();
          theRequest[y] = unescape(srtArry.join("="));
        }
      }
      return theRequest;
    },
    // 车牌颜色选择
    handleplateColor(index) {
      switch (index) {
        case 0:
          this.plateColor = 1;
          break;
        case 1:
          this.plateColor = 2;
          break;
        case 2:
          this.plateColor = 3;
          break;
        case 3:
          this.plateColor = 4;
          break;
        case 4:
          this.plateColor = 5;
          break;
      }
      this.nowIndex = index;
    },
    // 关注或查看按钮
    checkaccount() {
      let { appletsList } = this;
      window.open(appletsList.url, "_blank");
    },
    // 关闭弹框
    closealert() {
      let userAgent = window.navigator.userAgent.toLowerCase();
      if (userAgent.match(/Alipay/i) == "alipay") {
        AlipayJSBridge.call("closeWebview");
      }
      if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
        WeixinJSBridge.call("closeWindow");
      }
    },
    // 选择已经认证的车牌
    plateClickHandle(plate) {
      this.keyBoard.value = [];
      let _plates = JSON.parse(JSON.stringify(plate.plates));
      this.keyBoard.value = _plates;
      this.plateColor = plate.color;
      this.plateType = plate.plateType;
    },
    // 取证前加载的数据
    getData() {
      let appId = localStorage.getItem("appId");
      // 拿到路由中的参数
      this.roadId = this.$route.query.roadId;
      this.berthId = this.$route.query.berthId;
      // 解构出参数
      let { roadId, berthId } = this;
      // 使用微信或者支付宝的id
      let userId = localStorage.getItem("openId") || localStorage.getItem("alipayuserId");
      this.$axios
        .post(`${this.domain}/api/pay/berthStatus`, {
          appId,
          roadId,
          berthId,
          userId,
        })
        .then((res) => {
          if (res.data.code == 404) {
            let userAgent = window.navigator.userAgent.toLowerCase();
            this.$alert(`${res.data.msg}`, "提示")
              .then(() => {
                if (userAgent.match(/Alipay/i) == "alipay") {
                  AlipayJSBridge.call("closeWebview");
                }
                if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
                  WeixinJSBridge.call("closeWindow");
                }
              })
              .catch(() => {
                if (userAgent.match(/Alipay/i) == "alipay") {
                  AlipayJSBridge.call("closeWebview");
                }
                if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
                  WeixinJSBridge.call("closeWindow");
                }
              });
          } else if (res.data.code == 202) {
            // 重新请求判断用户是否有认证过，认证过的车牌直接展示让用户选择
            this.berthNo = res.data.berthNo;
            let appId = localStorage.getItem("appId");
            let userId = localStorage.getItem("openId") || localStorage.getItem("alipayuserId");
            this.$axios
              .post(`${this.domain}/api/pay/authCars`, {
                appId,
                userId,
              })
              .then((res) => {
                if (res.data.code == 200) {
                  this.recordPlateList = res.data.data.map((item) => ({
                    id: item.id,
                    plateNo: item.plateNo,
                    color: item.color,
                    plateType: item.plateType,
                    plates: item.plates,
                  }));
                } else {
                  this.$alert(`${res.data.msg}`, "提示")
                    .then(() => {
                      return;
                    })
                    .catch(() => {
                      return;
                    });
                }
              });
            return;
          } else {
            let parkRecordId = res.data.parkRecorId;
            localStorage.setItem("parkRecordId", parkRecordId);
            this.$router.push("/inpark/parkingpay");
          }
        });
    },
    // 确认取证
    onSubmit() {
      let { keyBoard } = this;
      let plateNo = "";
      let plateNoList = [];
      // 判断车牌号数组是否为空与需要满足7位数或8位数，用数组进行后续的判断
      if (keyBoard.value[6] !== "") {
        plateNoList = keyBoard.value.filter((item) => {
          plateNo += item;
          return item !== "";
        });
      }
      let { plateType, plateColor, berthId } = this;
      let appId = localStorage.getItem("appId");
      let itemId = localStorage.getItem("itemId");
      let userId = localStorage.getItem("openId") || localStorage.getItem("alipayuserId");
      // 取证上传
      if (plateNoList.length == 7 || plateNoList.length == 8) {
        this.loading = true;
        this.$axios
          .post(`${this.domain}/api/pay/h5Evidence`, {
            appId,
            berthId,
            userId,
            plateNo,
            plateType,
            plateColor,
            evidenceFrom: 3,
          })
          .then((res) => {
            if (res.data.code == 200) {
              let userAgent = window.navigator.userAgent.toLowerCase();
              if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
                this.$axios
                  .post(`${this.domain}/api/pay/followAccount`, {
                    appId,
                    itemId,
                    openId: userId,
                  })
                  .then((res) => {
                    this.loading = false;
                    this.appletsList = res.data.data;
                    this.showalert = true;
                  });
              } else {
                this.loading = false;
                this.$alert(`${res.data.msg}`, "提示")
                  .then(() => {
                    if (userAgent.match(/Alipay/i) == "alipay") {
                      AlipayJSBridge.call("closeWebview");
                    }
                    if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
                      WeixinJSBridge.call("closeWindow");
                    }
                  })
                  .catch(() => {
                    if (userAgent.match(/Alipay/i) == "alipay") {
                      AlipayJSBridge.call("closeWebview");
                    }
                    if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
                      WeixinJSBridge.call("closeWindow");
                    }
                  });
              }
            } else {
              this.loading = false;
              this.$alert(`${res.data.msg}`, "提示")
                .then(() => {
                  return;
                })
                .catch(() => {
                  return;
                });
            }
          });
      } else {
        this.$alert("请填写车牌号", "提示")
          .then(() => {
            return;
          })
          .catch(() => {
            return;
          });
      }
    },
    // 蓝牌选择
    blueHandle() {
      this.plateColor = 1;
    },
    // 黄牌选择
    yelloHandle() {
      this.plateColor = 2;
    },
    // 黑牌选择
    blackHandle() {
      this.plateColor = 3;
    },
    // 白牌选择
    whiteHandle() {
      this.plateColor = 4;
    },
    // 绿牌选择
    greenHandle() {
      this.plateColor = 5;
    },
    //点击车牌输入框
    carPlateInput(res) {
      this.keyBoard.isShow = true; //显示键盘
      this.keyBoard.indexNum = res; //设置键盘焦点下标
    },
    //键盘点击返回的值
    keyboardValue(res) {
      this.keyBoard.value.splice(this.keyBoard.indexNum, 1, res);
      // this.$setObject(this.keyBoard.value, this.keyBoard.indexNum, res);
    },
    //删除键盘值
    keyboardDel(res) {
      this.keyBoard.value.splice(res, 1, "");
      // this.$setObject(this.keyBoard.value, res, "");
    },
    //关闭
    keyboardClose(res) {
      this.keyBoard.isShow = res;
      this.keyBoard.indexNum = ""; //关闭键盘键盘高亮取消
    },
    //车牌焦点下标
    keyboardIndex(res) {
      this.keyBoard.indexNum = res;
    },
    // 车牌下标展示
    getmenuItem(index) {
      console.log(index);
    },
  },
};
</script>

<style lang="scss" scoped>
.forensics {
  position: relative;
  min-height: 100vh;
  margin-bottom: 75px;
  overflow-y: auto;

  .platecont {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-top: 15px;

    .title-text {
      box-sizing: border-box;
      width: 100%;
      padding: 0 15px;

      .inbox {
        border: 2px dashed #2370ff;
        height: 60px;
        line-height: 60px;
        text-align: center;
        font-size: 20px;
        color: #2370ff;
        background-color: rgba(35, 112, 255, 0.1);
      }
    }

    .plateinput-title {
      box-sizing: border-box;
      padding: 15px;
      width: 100%;
      font-size: 18px;
      text-align: left;
    }

    .plate-box {
      box-sizing: border-box;
      padding: 0 15px;

      .input-plate-wrap {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .plate-for {
          position: relative;

          .plate-item {
            box-sizing: border-box;
            position: relative;
            height: 50px;
            line-height: 50px;
            width: 36px;
            font-size: 18px;
            text-align: center;
            box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.08);
            border-radius: 5px;
            background-color: #ffffff;
          }

          .plate-item-new {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 50px;
            box-sizing: border-box;
            background-color: rgba(0, 222, 185, 0.1);
            border: 1px dashed #00bc9d;
            border-radius: 5px;
            font-size: 18px;
          }
        }

        .point {
          display: flex;
          width: 14px;
          height: 14px;
          border-radius: 14px;
          margin-top: 40px;
          background-color: #c3c2cb;
        }

        .icondiandongche {
          color: green;
          font-size: 20px;
        }
      }
    }

    .plateColor {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      padding: 0 15px;

      .plateColor-item {
        display: flex;
        align-items: bottom;
        padding: 4px 10px;
        text-align: center;
        font-size: 12px;
        color: #a3a3a3;
        border: 1px solid rgb(229, 229, 229);
        border-radius: 5px;
      }

      :nth-child(5) {
        background-color: #00deb9;
        color: white;
        margin-right: 0;
      }
    }

    .car-type {
      box-sizing: border-box;
      padding: 0 15px;
      width: 100%;
      margin-top: 20px;

      .car-type-title {
        margin-bottom: 12px;
        font-size: 18px;
      }

      .car-type-checkbox {
        font-size: 15px;
      }
    }

    .record-plate {
      box-sizing: border-box;
      padding: 0 15px;
      width: 100%;
      margin-top: 20px;

      .record-plate-title {
        font-size: 18px;
      }

      .plate-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px 0;
        width: 100%;
        overflow: auto;

        .license-plate {
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 5px;
          margin-right: 10px;
          width: 90px;
          height: 30px;
          border-radius: 3px;
          background-color: #4395ff;

          .number {
            padding: 2px 8px;
            border-radius: 3px;
            text-align: center;
            font-size: 14px;
            color: white;
            border: 1px solid white;
          }
        }
        :last-child {
          margin-right: 0;
        }
      }
    }

    .step {
      box-sizing: border-box;
      margin-top: 5px;
      width: 100%;
      .steptext {
        padding: 15px;
        font-size: 18px;
      }
      .firststep {
        box-sizing: border-box;
        padding: 0 13px;
        width: 100%;

        .firstbox {
          height: 40px;
          line-height: 40px;
          font-size: 15px;
          background-color: rgb(248, 248, 248);
          border: 1px dashed rgb(229, 229, 229);
          text-align: center;
          color: #a3a3a3;
        }
      }

      .stepicon {
        margin: 15px 0;
        width: 100%;
        text-align: center;
        font-size: 50px;
        color: #e5e5e5;
      }
    }

    .submitbox {
      position: fixed;
      bottom: 25px;
      left: 15px;
      right: 15px;
      height: 44px;

      button {
        width: 100%;
        background-color: #2370ff;
      }
    }
  }
}

.alert-titel {
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: rgb(53, 206, 15);
}
.alert-text {
  margin-top: 10px;
  font-size: 18px;
  color: rgb(105, 105, 105);
  text-align: center;
}
.alert-btn {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.active-click {
  color: white !important;
  background: rgb(35, 112, 255);
}
</style>
